import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0078D7",
    },
    secondary: {
      main: "#E81123",
    },
    text: {
      primary: "#FFF",
      secondary: "#A6A6A6",
    },
    background: {
      default: "#012456",
      paper: "#002050",
    },
    error: {
      main: "#E81123",
    },
  },
  typography: {
    fontFamily: "Segoe UI, sans-serif",
    fontWeightRegular: 400,
    fontWeightBold: 600,
  },
});

export default theme;