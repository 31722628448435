import './App.css';
import QuickCommands from './Components/QuickCommands';
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QuickCommands />
      </ThemeProvider>
  );
}

export default App;
