import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";

export async function getData() {

    const spreadsheetId = '11bcOno7bhZ_fIvNs6aFn1CUUNy3GRjhoewFdsp3vfO4',

        response = await fetch(`https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json`),
        result = await response.text(),
        json = JSON.parse(result.replace(/.*google.visualization.Query.setResponse\({(.*?)}\);?/s, '{$1}'));

    // console.log(json);

    // `table.cols` element contains headings
    // we will use them to build our data array
    const headings = json.table.cols.map(item => item.label);

    // console.log(headings);

    // data of each row is associated to the headings
    let data = json.table.rows.map(item => {
        // console.log(item);
        let row = {};
        item.c.forEach((cell, idx) => {
            row[headings[idx]] = cell?.v ?? null;
        });
        return item.c.map(d => d.v);
    });

    return data;
}

const PowerShellTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-input": {
        fontFamily: "Consolas, monospace",
        fontSize: "1rem",
        color: theme.palette.text.primary,
        background: 'black'
    },
    "& .MuiInput-underline:before": {
        borderBottomColor: theme.palette.text.primary,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottomColor: theme.palette.text.primary,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: theme.palette.error.main,
    },
}));

const QuickCommands = ({ title, value }) => {
    const [data, setData] = useState([]);
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        async function fetchData() {

            const json = await getData();
            setData(json);
        }
        fetchData();
    }, []);
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    console.log(data);
    return (
        <Grid container spacing={2} alignItems="center">
            {data.map(([label, value], index) => (
               
                <Grid item xs={12} sm={8} key={index}>
                    {label}
                    <PowerShellTextField
                        fullWidth
                        value={value}
                        variant="standard"
                        multiline
                        rows={value.split(/\r\n|\r|\n/).length}
                        InputProps={{
                            endAdornment: (
                                <CopyToClipboard text={value} onCopy={handleCopy}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{ textTransform: "none" }}
                                    >
                                        {copied ? "Copied!" : "Copy"}
                                    </Button>
                                </CopyToClipboard>
                            ),
                        }}

                    />
                </Grid>))}
        </Grid>
    );
};

export default QuickCommands;
